import { template as template_b86ac8f9de79439dbdc3c34a747e522a } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_b86ac8f9de79439dbdc3c34a747e522a(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
