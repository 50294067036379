import { template as template_630043a4a62447f4ad642ee88e54acee } from "@ember/template-compiler";
const FKText = template_630043a4a62447f4ad642ee88e54acee(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
