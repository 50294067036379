import { template as template_f0bffd7d6ae2415182446f26f49b56f9 } from "@ember/template-compiler";
const FKLabel = template_f0bffd7d6ae2415182446f26f49b56f9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
